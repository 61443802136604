
interface menuType {
    category: string[],
    groups: Array<{
        name: string[],
        level: number,
        items: Array<{
            name: string[],
            subtitle?: string[],
            price?: string[]
        }>
    }>
}

export const menu = [
    {
        category: ["Menu à la carte", "Menu à la carte"],
        groups: [
            {
                name: ["Předkrmy", "Starters"],
                level: 1,
                items: [
                    { name: ["Carpaccio", "Beef Carpaccio"], subtitle: ["majonéza z černého česneku, olivy taggiasche, rukola, grana padano", "mayonnaise from black garlic, Taggiasca olives, arugula, Grana Padano"], price: ["319"] },
                    // { name: ["Lososový tatarák", "Salmon Tartar"], subtitle: ["okurka, uzená majonéza", "Cucumber, Smoked Mayonnaise"], price: ["279"] },
                    { name: ["Tygří krevety", "Tiger Prawns"], subtitle:["gremolata, rukola, sezam, ciabatta", "gremolata, arugula, sesame, ciabatta"], price: ["299"] },
                    { name: ["Bruschetta", "Bruschetta"], subtitle: ["barevná rajčata, stracciatella di burrata", "colored tomatoes, stracciatella di burrata"], price: ["239"] },
                    { name: ["Selekce italských sýrů a uzenin", "Selection of Italian cheeses and cured meats"], subtitle:["olivy taggiasche, sušená rajčata,pinie, grissini", "Taggiasca olives, sun-dried tomatoes, pine nuts, grissini"], price: ["299"] }
                ]
            },
            {
                name: ["Polévky", "Soups"],
                level: 1,
                items: [
                    { name: ["Kuřecí vývar", "Chicken Broth"], subtitle: ["maso, zelenina, celestýnské nudle, pažitka", "meat, vegetables, celestine noodles, chives"], price: ["99"] },
                    { name: ["Rajčatový krém", "Tomato cream"], subtitle: ["ricotta", "ricotta"], price: ["109"] }
                ]
            },
            {
                name: ["Pasta a gnocchi", "Pasta & Gnocchi"],
                level: 1,
                items: [
                    { name: ["Aglio olio e peperoncino / flank steak", "Aglio Olio e Peperoncino / Flank Steak"], price: ["199/319"] },
                    { name: ["Alla Carbonara", "Alla Carbonara"], subtitle: ["pancetta, žloutky, grana padano", "Pancetta, Egg Yolks, Grana Padano"], price: ["279"] },
                    { name: ["Gamberetti", "Gamberetti"], subtitle: ["krevety, máslo, grana padano, pepř", "shrimps, butter, Grana Padano, pepper"], price: ["339"] },
                    // { name: ["Limone", "Limone"], subtitle: ["grana padano, kuřecí maso, smetana limeta", "Grana Padano, Chicken, Cream Lime"], price: ["289"] },
                    { name: ["Al Pesto Genovese", "Al Pesto Genovese"], subtitle: ["kuřecí prso, bazalka, pinie, česnek, grana padano", "chicken breast, basil, pine nuts, garlic, Grana Padano"], price: ["299"] },
                    // { name: ["Panna Prosciutto Crudo", "Panna Prosciutto Crudo"], subtitle: ["parmská šunka, hrášek, smetana, grana padano", "Prosciutto Ham, Peas, Cream, Grana Padano"], price: ["299"] },
                    { name: ["Alla Tagliata", "Alla Tagliata"], subtitle: ["hovězí svíčková, červená cibule, rajčata, rukola", "beef tenderloin, red onion, tomatoes, arugula"], price: ["359"] },
                    { name: ["Spinaci e gorgonzola", "Spinaci e gorgonzola"], subtitle: ["baby špenát, gorgonzola, vlašské ořechy", "baby spinach, Gorgonzola, walnuts"], price: ["299"] },
                    { name: ["Raviolli", "Raviolli"], subtitle: ["špenát, ricotta, máslo, šalvěj, pinie", "spinach, ricotta, butter, sage, pine nuts"], price: ["289"] },
                    { name: ["Lasagne Bolognese", "Lasagne Bolognese"], subtitle: ["hovězí mletý krk, rajčatová omáčka, grana padano", "beef ground neck, tomato sauce, Grana Padano"], price: ["309"] },
                ]
            },
            {
                name: ["Risotta", "Risottos"],
                level: 1,
                items: [
                    { name: ["Risotto ai funghi porcini", "Risotto ai Funghi Porcini"], subtitle: ["máslo, grana padano, hřib hnědý", "Butter, Grana Padano, Porcini Mushrooms"], price: ["299"] },
                    { name: ["Risotto parmigiano", "Risotto Parmigiano"], subtitle: ["hovězí líčka, gremolata", "beef cheeks, gremolata"], price: ["449"] },
                ]
            },
            {
                name: ["Ryby", "Fish"],
                level: 1,
                items: [
                    // { name: ["Filet z pražmy královské", "King Bream Fillet"], subtitle: ["koprový jogurt, chorizo, baby brambory, šalotka", "Dill Yogurt, Chorizo, Baby Potatoes, Shallots"], price: ["419"] },
                    // { name: ["Ryba dne", "Fish of the Day"], subtitle: ["grilovaná zelenina, holadská omáčka", "Grilled Vegetables, Hollandaise Sauce"], price: ["499"] },
                    { name: ["Mořský vlk", "Sea bass"], subtitle: ["konfitovaný brambor, hrášková omáčka, ředkvička", "Confit potato, pea sauce, radish"], price: ["369"] },
                ]
            },
            {
                name: ["Maso", "Meat"],
                level: 1,
                items: [
                    { name: ["Hovězí svíčková / flank steak", "Beef Tenderloin / Flank Steak"], subtitle: ["bramborové hranolky, omáčka z kampotského pepře", "potato fries, Kampot pepper sauce"], price: ["649/519"] },
                    { name: ["Kuřecí prso", "Chicken Breast"], subtitle: ["koprový dip, baby brambory, chorizo", "dill dip, baby potatoes, chorizo"], price: ["359"] },
                    { name: ["New One burger", "New One Burger"], subtitle: ["hovězí mletý krk, cheddar, rajče, cibulová marmeláda, chipotle, uzená majonéza, bramborové hranolky", "beef ground neck, cheddar, tomato, onion marmalade, chipotle, smoked mayonnaise, potato fries"], price: ["429"] },
                ]
            },
            // {
            //     name: ["Každý pátek a sobotu:", "Every Friday and Saturday:"],
            //     level: 2,
            //     items: [
            //         { name: ["New One burger", "New One Burger"], subtitle: ["hovězí mletý krk, cheddar, rajče, cibulová marmeláda, chipotle, uzená majonéza, bramborové hranolky", "Ground Beef, Cheddar, Tomato, Onion Marmalade, Chipotle, Smoked Mayonnaise, French Fries"], price: ["419"] },
            //     ]
            // },
            {
                name: ["Přílohy", "Side Dishes"],
                level: 1,
                items: [
                    { name: ["Baby brambory", "Baby Potatoes"], subtitle: [""], price: ["59"] },
                    { name: ["Míchaný listový salát", "Mixed Leaf Salad"], subtitle: [""], price: ["69"] },
                    // { name: ["Mačkaný hrášek", "Squashed Peas"], subtitle: [""], price: ["69"] },
                    { name: ["Grilovaná zelenina", "Grilled Vegetables"], subtitle: [""], price: ["89"] },
                    { name: ["Focaccia, košík", "Focaccia, Basket"], subtitle: [""], price: ["59"] },
                ]
            },
            {
                name: ["Saláty", "Salads"],
                level: 1,
                items: [
                    { name: ["Caesar salát", "Caesar Salad"], subtitle: ["kuřecí maso, pancetta, krutony, grana padano", "chicken, pancetta, croutons, Grana Padano"], price: ["329"] },
                    { name: ["Salát s gratinovaným kozím sýrem", "Salad with Gratinated Goat Cheese"], subtitle: ["mango dresink, avokádo, jahody", "mango dressing, avocado, strawberries"], price: ["359"] },
                    { name: ["Salát s hovězí svíčkovou", "Salad with Beef Tenderloin"], subtitle: ["míchaný salát, olivy taggiasche, cherry rajčata, smažená cizrna, sezam", "mixed salad, Taggiasca olives, cherry tomatoes, fried chickpeas, sesame"], price: ["389"] },
                ]
            },
            {
                name: ["Pochutiny", "Snacks"],
                level: 1,
                items: [
                    { name: ["Pražené mandle", "Roasted Almonds"], subtitle: [""], price: ["89"] },
                    { name: ["Grissini", "Grissini"], subtitle: [""], price: ["59"] },
                    { name: ["Marinované olivy", "Marinated Olives"], subtitle: [""], price: ["69"] },
                ]
            },
            {
                name: ["Dezerty", "Desserts"],
                level: 1,
                items: [
                    { name: ["Tiramisu", "Tiramisu"], subtitle: [""], price: ["129"] },
                ]
            },




        ],
    },
    {
        category: ["Snídaně à la carte", "à la carte breakfast"],
        groups: [
            {
                name: [""],
                level: 1,
                items: [
                    { name: ["Káva a croissant", "Coffee and Croissant"], subtitle: [""], price: ["89"] },
                    { name: ["Káva, croissant a pomerančový džus", "Coffee, Croissant & Orange Juice "], subtitle: [""], price: ["119"] },
                    { name: ["New One granola", "New One Granola"], subtitle: ["řecký jogurt, lísko-čokoládové máslo, ovoce", "Greek yoghurt, hazelnut-chocolate butter, fruit"], price: ["179"] },
                    // { name: ["Buchtičky s krémem", "Sweet Buns with Vanilla Cream"], price: ["209"] },
                    { name: ["Palačinka s povidly", "Pancake with jam"], subtitle: ["šlehaný tvaroh, čokoláda, ovoce", "whipped curd, chocolate, fruit"], price: ["189"] },
                    { name: ["Ovesná kaše", "Oatmeal"], subtitle: ["pečené meruňky, lísko-čokoládové máslo", "baked apricots, hazelnut-chocolate butter"], price: ["179"] },
                    { name: ["3 vejce do skla", "3 Eggs in a Glass"], subtitle: ["grana padano, pažitka, toast, máslo", "Grana Padano, Chives, Toast, Butter"], price: ["149"] },
                    { name: ["Vejce míchaná 3 ks s pažitkou / s cibulkou a pažitkou", "Scrambled Eggs (3 Eggs) with Chives / with Onions and Chives"], subtitle: ["máslo, pečivo", "Butter, Bread"], price: ["109/119"] },
                    { name: ["Vaječná omeleta ze 3 vajec", "Egg Omelette with 3 Eggs"], subtitle: ["pražská šunka, baby špenát, uzená scamorza, pečivo", "Prugue ham, baby spinach, smoked scamorza cheese, bread"], price: ["199"] },
                    { name: ["Pečená brioška s trhaným vepřovým masem", "Baked brioche with pulled pork"], subtitle: ["ztracené vejce, baby špenát, holandská omáčka", "poached egg, baby spinach, Hollandaise sauce"], price: ["269"] },
                    { name: ["Anglická snídaně", "English Breakfast"], subtitle: ["vejce, bavorská klobása, fazole, slanina, žampiony, rajče, máslo, pečivo", "eggs, Bavarian sausage, beans, bacon, mushrooms, tomato, butter, bread"], price: ["299"] },
                    { name: ["Vejce Benedikt", "Eggs Benedict"], subtitle: ["zastřené vejce, holandská omáčka, pečená šunka od kosti, pečivo", "poached egg, Hollandaise sauce, baked ham off the bone, bread"], price: ["239"] },
                    { name: ["Vejce Florentine", "Eggs Florentine"], subtitle: ["zastřené vejce, holandská omáčka, baby špenát, pečivo", "poached egg, Hollandaise sauce, baby spinach, bread"], price: ["239"] },


                ]
            },
            {
                name: ["Nápoje", "Drinks"],
                level: 1,
                items: [
                    { name: ["Pomerančový fresh juice", "Fresh Orange Juice"], subtitle: ["0,2l"], price: ["99"] },
                    { name: ["Jablečný fresh juice", "Fresh Apple Juice"], subtitle: ["0,2l"], price: ["99"] },
                    { name: ["Grapefruitový fresh juice", "Fresh Grapefruit Juice"], subtitle: ["0,2l"], price: ["99"] },
                    { name: ["Jahodové smoothie", "Strawberry Smoothie"], subtitle: ["0,4l"], price: ["99"] },
                    { name: ["Banánové smoothie s pomerančem", "Banana Smoothie with Orange"], subtitle: ["0,4l"], price: ["99"] },
                    { name: ["Smoothie z lesního ovoce", "Forest Fruits Smoothie"], subtitle: ["0,4l"], price: ["99"] },

                ]

            },
            {
                name: ["Káva", "Coffee"],
                level: 1,
                items: [
                    { name: ["Espresso", "Espresso"], subtitle: [""], price: ["59"] },
                    { name: ["Espresso speciál", "Espresso of the Day"], subtitle: ["(dle denní nabídky)", null], price: ["59"] },
                    { name: ["Espresso ristretto", "Espresso Ristretto"], subtitle: [""], price: ["59"] },
                    { name: ["Espresso lungo", "Espresso Lungo"], subtitle: [""], price: ["59"] },
                    { name: ["Espresso doppio", "Espresso Doppio"], subtitle: [""], price: ["79"] },
                    { name: ["Espresso tonic", "Espresso Tonic"], subtitle: [""], price: ["89"] },
                    { name: ["Espresso macchiato", "Espresso Macchiato"], subtitle: [""], price: ["59"] },
                    { name: ["Cappuccino", "Cappuccino"], subtitle: [""], price: ["74"] },
                    { name: ["Cappuccino doppio", "Cappuccino Doppio"], subtitle: [""], price: ["94"] },
                    { name: ["Flat white", "Flat White"], subtitle: [""], price: ["89"] },
                    { name: ["Caffè latte macchiato", "Caffè Latte Macchiato"], subtitle: [""], price: ["79"] },
                    { name: ["Caffè latte", "Caffè Latte"], subtitle: [""], price: ["79"] },
                    { name: ["Caffè latte jumbo", "Caffè Latte Jumbo"], subtitle: [""], price: ["99"] },
                    { name: ["Frappuccino", "Frappuccino"], subtitle: [""], price: ["84"] },
                    { name: ["Caffè gelato", "Caffè Gelato"], subtitle: ["(se zmrzlinou a šlehačkou)", "(with Ice Cream and Whipped Cream)"], price: ["89"] },
                    { name: ["Caffè bombardino", "Caffè Bombardino"], subtitle: [""], price: ["89"] },
                    { name: ["Caffè irlandese", "Caffè Irlandese"], subtitle: ["(s whiskey a šlehačkou)", "(with Whiskey and Cream)"], price: ["99"] },
                    { name: ["Příchuť do kávy dle nabídky", "Coffee Flavour of the Day"], price: ["10"] },
                    { name: ["Všechny kávy mohou být připraveny bezkofeinové a za příplatek 20,- Kč s bezlaktózovým mlékem.", "All coffees can be prepared decaffeinated for an additional fee of 20 CZK with lactose-free milk."] }
                ]

            },
            {
                name: ["Čaje", "Tea"],
                level: 1,
                items: [
                    { name: ["Z čerstvé máty s medem", "Fresh Mint Tea with Honey"], subtitle: [""], price: ["69"] },
                    { name: ["Z čerstvého zázvoru s medem", "Fresh Ginger Tea with Honey"], subtitle: [""], price: ["69"] },
                    { name: ["Sypaný čaj Tea Forte", "Tea Forte Loose Leaf Tea"], subtitle: [""], price: ["69"] },

                ]

            },
        ],
    },
    {
        category: ["Nápojový list", "Drink menu"],
        groups: [
            {
                name: ["Nápoje", "Drinks"],
                items: [
                    { name: ["Bezinková limonáda", "Elderflower Lemonade"], price: ["79"] },
                    { name: ["Citronová limonáda", "Lemonade"], price: ["79"] },
                    { name: ["Zázvorová limonáda", "Ginger Lemonade"], price: ["79"] },
                    { name: ["Okurková limonáda", "Cucumber Lemonade"], price: ["79"] },
                    { name: ["Americká limonáda", "American Lemonade"], price: ["79"] },
                    { name: ["Pomerančový fresh juice", "Fresh Orange Juice"], price: ["99"] },
                    { name: ["Jablečný fresh juice", "Fresh Apple Juice"], price: ["99"] },
                    { name: ["Grapefruitový fresh juice", "Fresh Grapefruit Juice"], price: ["99"] },
                    { name: ["Jahodové smoothie", "Strawberry Smoothie"], price: ["99"] },
                    { name: ["Banánové smoothie s pomerančem", "Banana Smoothie with Orange"], price: ["99"] },
                    { name: ["Smoothie z lesního ovoce", "Forest Fruit Smoothie"], price: ["99"] },
                ]
            },
            {
                name: ["Vody", "Water"],
                items: [
                    { name: ["Mattoni perlivá", "Mattoni Sparkling"], price: ["49"] },
                    { name: ["Mattoni jemně perlivá", "Mattoni Slightly Sparking"], price: ["49"] },
                    { name: ["Mattoni neperlivá", "Matonni Still"], price: ["49"] },
                    { name: ["Roemerquelle perlivá", "Roemerquelle Sparkling"], price: ["59 / 99"] },
                    { name: ["Roemerquelle neperlivá", "Roemerquelle Still"], price: ["59 / 99"] },
                    { name: ["Roemerquelle lemongrass", "Roemerquelle Lemongrass"], price: ["69"] },
                    { name: ["Džbán vody", "A Jug of Water"], subtitle: ["s čerstvou mátou a citrónem", "with Fresh Mint and Lemon"], price: ["29 / 49"] },
                ]
            },
            {
                name: ["Džusy", "Fruit Juice"],
                items: [
                    { name: ["Cappy"], subtitle: ["pomeranč, jablko, multivitamin, ananas, jahoda, černý rybíz, meruňka, hruška, grep", "Orange, Apple, Multivitamin, Pineapple, Strawberry, Black Currant, Apricot, Pear, Grapefruit"], price: ["59"] },
                ]
            },
            {
                name: ["Nealkoholické nápoje", "Soft Drinks"],
                items: [
                    { name: ["Coca-Cola"], price: ["59"] },
                    { name: ["Coca-Cola Zero", "Coca-Cola Zero"], price: ["59"] },
                    { name: ["Coca-Cola ochucená", "Flavoured Coca-Cola"], subtitle: ["vanilla, cherry, passion fruit", "Vanilla, Cherry, Passion Fruit"], price: ["59"] },
                    { name: ["Sprite, Fanta"], price: ["59"] },
                    { name: ["Kinley Tonic Water, Kinley Ginger Ale, Kinley Bitter Rose"], price: ["59"] },
                    { name: ["Crodino aperitivo nealko", "Crodino aperitivo (Alcohol-free)"], price: ["69"] },
                    { name: ["Ledový čaj", "Iced Tea"], subtitle: ["broskev, citron", "Peach, Lemon"], price: ["69"] },
                    { name: ["Red Bull"], price: ["79"] },
                    { name: ["Fever-Tree Premium Indian Tonic Water"], price: ["79"] },
                ]
            },
            {
                name: ["Pivo", "Beer"],
                items: [
                    { name: ["Pilsner Urquell čepovaný", "Pilsner Urquell (draught)"], price: ["59"] },
                    { name: ["Radegast Birell lahvový", "Radegast Birell (bottled)"], price: ["49"] },
                    { name: ["Stella Artois lahvová", "Stella Artois (bottled)"], price: ["59"] },
                    { name: ["Apple Cider lahvový", "Apple Cider (bottled)"], price: ["59"] },
                    { name: ["Corona lahvová", "Corona (bottled)"], price: ["79"] },
                ]
            },
            {
                name: ["Káva", "Coffee"],
                items: [
                    { name: ["Espresso", "Espresso"], subtitle: [""], price: ["59"] },
                    { name: ["Espresso speciál", "Espresso of the Day"], subtitle: ["(dle denní nabídky)", null], price: ["59"] },
                    { name: ["Espresso ristretto", "Espresso Ristretto"], subtitle: [""], price: ["59"] },
                    { name: ["Espresso lungo", "Espresso Lungo"], subtitle: [""], price: ["59"] },
                    { name: ["Espresso doppio", "Espresso Doppio"], subtitle: [""], price: ["79"] },
                    { name: ["Espresso tonic", "Espresso Tonic"], subtitle: [""], price: ["89"] },
                    { name: ["Espresso macchiato", "Espresso Macchiato"], subtitle: [""], price: ["59"] },
                    { name: ["Cappuccino", "Cappuccino"], subtitle: [""], price: ["74"] },
                    { name: ["Cappuccino doppio", "Cappuccino Doppio"], subtitle: [""], price: ["94"] },
                    { name: ["Flat white", "Flat White"], subtitle: [""], price: ["89"] },
                    { name: ["Caffè latte macchiato", "Caffè Latte Macchiato"], subtitle: [""], price: ["79"] },
                    { name: ["Caffè latte", "Caffè Latte"], subtitle: [""], price: ["79"] },
                    { name: ["Caffè latte jumbo", "Caffè Latte Jumbo"], subtitle: [""], price: ["99"] },
                    { name: ["Frappuccino", "Frappuccino"], subtitle: [""], price: ["84"] },
                    { name: ["Caffè gelato", "Caffè Gelato"], subtitle: ["(se zmrzlinou a šlehačkou)", "(with Ice Cream and Whipped Cream)"], price: ["89"] },
                    { name: ["Caffè bombardino", "Caffè Bombardino"], subtitle: [""], price: ["89"] },
                    { name: ["Caffè irlandese", "Caffè Irlandese"], subtitle: ["(s whiskey a šlehačkou)", "(with Whiskey and Cream)"], price: ["99"] },
                    { name: ["Příchuť do kávy dle nabídky", "Coffee Flavour of the Day"], subtitle: [""], price: ["10"] }
                ]
            },
            {
                name: ["Čaje", "Tea"],
                items: [
                    { name: ["Z čerstvé máty s medem", "Fresh Mint Tea with Honey"], subtitle: [""], price: ["69"] },
                    { name: ["Z čerstvého zázvoru s medem", "Fresh Ginger Tea with Honey"], subtitle: [""], price: ["69"] },
                    { name: ["Sypaný čaj Tea Forte s medem", "Tea Forte Loose Leaf Tea with honey"], subtitle: [""], price: ["69"] },

                ]

            },
            {
                name: ["Teplé nápoje", "Hot Drinks"],
                items: [
                    { name: ["Horká čokoláda", "Hot Chocolate"], price: ["69"] },
                    { name: ["Svařené víno", "Mulled Wine"], price: ["69"] },
                    { name: ["Bombardino se šlehačkou", "Bombardino with Whipped Cream"], price: ["79"] },
                ]
            },
            {
                name: ["Vermuty", "Vermouth"],
                items: [
                    { name: ["Lillet"], price: ["69"] },
                    { name: ["Martini Extra Dry"], price: ["69"] },
                    { name: ["Martini Bianco"], price: ["69"] },
                    { name: ["Martini Rosato"], price: ["69"] },
                    { name: ["Martini Rosso"], price: ["69"] },
                ]
            },
            {
                name: ["Likéry/bittery", "Liquors/Bitters"],
                items: [
                    { name: ["Campari Bitter"], price: ["69"] },
                    { name: ["Jägermeister"], price: ["69"] },
                    { name: ["Amaretto Disaronno Originale"], price: ["69"] },
                    { name: ["Porto Ruby Sandeman"], price: ["69"] },
                    { name: ["Jack Daniel’s Honey"], price: ["79"] },
                    { name: ["Becherovka"], price: ["59"] },
                    { name: ["Fernet Stock"], price: ["59"] },
                    { name: ["Baileys Irish Cream"], price: ["59"] },
                ]
            },
            {
                name: ["Giny", "Gin"],
                items: [
                    { name: ["Beefeater London Dry Gin"], price: ["69"] },
                    { name: ["Bombay Sapphire Dry Gin"], price: ["69"] },
                    { name: ["Plymouth Gin"], price: ["79"] },
                    { name: ["Tanqueray London Dry Gin"], price: ["79"] },
                    { name: ["Hendrick's Gin"], price: ["89"] },
                ]
            },
            {
                name: ["Whisk(e)y"],
                items: []
            },
            {
                name: ["Irské whiskey", "Irish Whiskey"],
                level: 2,
                items: [
                    { name: ["Jameson"], price: ["69"] },
                    { name: ["Tullamore Dew"], price: ["69"] },
                    { name: ["Jameson Black Barrel"], price: ["99"] }
                ]
            },
            {
                name: ["Skotské whisky", "Scottish Whisky"],
                level: 2,
                items: [
                    { name: ["Chivas Regal 12"], price: ["90"] },
                    { name: ["Glenmorangie The Original"], price: ["130"] },
                    { name: ["The Glenlivet"], subtitle: ["15 Year Old French Oak Reserve"], price: ["150"] },
                    { name: ["Ardberg Ten"], price: ["150"] },
                ]
            },
            {
                name: ["Americké whiskey", "American Whiskey"],
                level: 2,
                items: [
                    { name: ["Jack Daniel's No. 7"], price: ["79"] },
                ]
            },
            {
                name: ["Bourbony", "Bourbon"],
                level: 1,
                items: [
                    { name: ["Wild Turkey"], price: ["69"] },
                    { name: ["Four Roses"], price: ["69"] },
                ]
            },
            {
                name: ["Koňaky", "Cognac"],
                level: 1,
                items: [
                    { name: ["Martell V.S."], price: ["95"] },
                    { name: ["Martell V.S.O.P"], price: ["150"] },
                    { name: ["Martell X.O"], price: ["450"] },
                    { name: ["Rémy Martin V.S.O.P."], price: ["150"] },
                    { name: ["Courvoisier X.O. Impérial"], price: ["300"] },
                ]
            },
            {
                name: ["Vodky", "Vodka"],
                level: 1,
                items: [
                    { name: ["Absolut vodka"], price: ["69"] },
                    { name: ["Absolut Citron/Mandarin/Raspberry/Vanilia"], price: ["69"] },
                    { name: ["Skyy vodka"], price: ["69"] },
                    { name: ["Grey Goose"], price: ["109"] }
                ]
            },
            {
                name: ["Rumy", "Rum"],
                level: 1,
                items: [
                    { name: ["Havana Club Añejo 3 Años"], price: ["69"] },
                    { name: ["Havana Club Especial"], price: [" 79"] },
                    { name: ["Havana Club Añejo 7 Años"], price: ["90"] },
                    { name: ["Legendario Ron"], price: ["120"] },
                    { name: ["Diplomático Reserva Exclusiva"], price: ["120"] },
                    { name: ["Ron Zacapa Centenario 23"], price: ["150"] },
                    { name: ["Ron Zacapa Centenario X.O."], price: ["350"] },
                ]
            },
            {
                name: ["Ostatní spirity", "Other Spirits"],
                level: 1,
                items: [
                    { name: ["Tequila Olmeca blanco / reposado"], price: ["69"] },
                    { name: ["Patrón Tequila blanco / reposado"], price: ["100"] },
                    { name: ["Grappa bianco Ramazzoti"], price: ["69"] },
                    { name: ["Metaxa 5*"], price: ["60"] },
                    { name: ["Metaxa 7*"], price: ["90"] },
                    { name: ["Metaxa 12*"], price: ["130"] },
                    { name: ["Slivovice Žufánek"], price: ["79"] },
                    { name: ["Hruškovice Žufánek"], price: ["79"] },
                ]
            },
        ]
    },
    {
        category: ["Vinný list"],
        groups: [
            {
                name: ["Moravská přívlastková vína", "Moravian Wines with Attributes"],
                items:[
                    {name:["vinařství: Filip Mlýnek (Dolní Dunajovice)", "Winery: Filip Mlýnek (Dolní Dunajovice)"],}
                ]
            },
            {
                name: ["Bílá vína", "White Wines"],
                level: 2,
                items: [
                    { name: ["PÁLAVA 2023 výběr z hroznů, vinařství Filip Mlýnek, suché, 0,75l", "PÁLAVA 2023 Selection of Grapes, winery Filip Mlýnek , dry, 0,75l"], subtitle: ["Krásný, čistý, aromatický výběr z hroznů. V chuti i vůni je cítit mandarinka, papája a včelí plást.", "A beautiful, clean, aromatic selection of grapes. The taste and aroma feature notes of tangerine, papaya, and honeycomb."], price: ["440"] },
                    { name: ["RÝNSKÝ RYZLINK 2023 výběr z hroznů, vinařství Filip Mlýnek, polosuché, 0,75l", "RHINE RIESLING 2023 Late Harvest, winery Filip Mlýnek , semi-sweet, 0,75l"], subtitle: ["Ovocný ryzlink, ve kterém převládá banánová slupka a máslové sušenky. Chuť je minerální až zemitá.", "A fruity Riesling with predominant notes of banana peel and butter biscuits. The taste is mineral with earthy undertones."], price: ["440"] },
                    { name: ["RÝNSKÝ RYZLINK 2023 pozdní sběr, vinařství Filip Mlýnek, polosladké, 0,75l", "RHINE RIESLING 2023 Late Harvest, winery Filip Mlýnek , Semi-sweet, 0.75l"], subtitle: ["Víno zlatožluté barvy a intenzivní vůně, ve kterém se prolíná vůně a chuť meruňky, medu a kandovaného ovoce. Uspokojí především dámy, které mají rady sladší vína.", "A golden-yellow wine with an intense aroma, featuring intertwined scents and flavors of apricot, honey, and candied fruit. It will particularly satisfy ladies who enjoy sweeter wines."], price: ["440"] },
                    { name: ["VLAŠSKÝ RYZLINK 2023 výběr z hroznů, vinařství Filip Mlýnek, suché, 0,75l", "WELSCHRIESLING 2023 Selection of Grapes, winery Filip Mlýnek, Dry, 0.75l"], subtitle: ["Velice příjemné víno s intenzivní vůní botrytických hroznů, chuť je plná s dlouhým koncem po sušených rozinkách. ", "Very pleasant wine with an intense scent of botrytized grapes, taste full and long-lasting with notes of dried raisins."], price: ["440"] },
                    { name: ["VELTLÍNSKÉ ZELENÉ 2023 pozdní sběr, vinařství Filip Mlýnek, suché, 0,75l", "GRÜNER VELTLINER 2023 Late harvest, winery Filip Mlýnek, Dry, 0.75l"], subtitle: ["Výrazný, minerálně pepřový Veltlín se silným kořením a mandlovou vůní. Chuť je dlouhá, středně plná s minerálním podtónem.", "A distinctive, minerally peppery Veltliner with strong spice and an almond aroma. The taste is long and medium-bodied with a mineral undertone."], price: ["440"] },
                ]
            },
            {
                name: ["Červená vína", "Red Wines"],
                level: 2,
                items: [
                    { name: ["ANDRÉ 2022 výběr z hroznů, vinařství Filip Mlýnek, suché, 0,75l", "ANDRÉ 2022 selection of Grapes, winery Filip Mlýnek, Dry, 0.75l"], subtitle: ["Ve vůni jsou cítit tóny kandovaného ovoce a divoké třešně, které přechází i do chuti. Uspokojí zejména milovníky plných červených vín.", "The aroma features notes of candied fruit and wild cherries, which also carry over into the taste. It will particularly satisfy lovers of full-bodied red wines."], price: ["490"] },
                    { name: ["ZWEIGELTREBE 2022 výběr z hroznů, vinařství Filip Mlýnek, suché, 0,75l", "ZWEIGELTREBE 2022 selection of Grapes, winery Filip Mlýnek, Dry, 0.75l"], subtitle: ["Ve vůni třešně, višně a čokoládový nádech. Chuť plná, dlouhá až lehounce přezrálá s tóny zemitosti. Víno zrálo 9 měsíců v sudech. Tříslovina v dochuti.", "The aroma presents cherries, sour cherries, and a hint of chocolate. The flavor is full, long-lasting, and slightly overripe, with earthy tones. The wine aged for 9 months in barrels, with tannins present in the finish"], price: ["590"] },
                ]
            },
            {
                name: ["Rozlévané PROSECCO", "Poured PROSECCO"],
                level: 1,
                items: [
                    { name: ["Sudové Prosecco DOC (Treviso)", "Prosecco, DOC (Treviso), Barrel"], subtitle: [""], price: ["0,1l / 50"] },
                ]
            },
            {
                name: ["Rozlévané víno", "Poured Wine"],
                level: 1,
                items: [
                    { name: ["Dle nabídky 0,1l / 0,75l", "According to current offer 0,1l / 0,75l"], subtitle: [""], price: ["40 / 290"] },
                ]
            },
            {
                name: ["Zahraniční vína", "Foreign Wines"]
            },
            {
                name: ["Bílá vína", "White Wines"],
                level: 2,
                items: [
                    { name: ["“PRENDO“ PINOT GRIGIO VIGNETI DELLE DOLOMITI 2023(ITA), IGT, Trentino – Alto Adige, vinařství Wilhelm Walch", "“PRENDO“ PINOT GRIGIO, VIGNETI DELLE DOLOMITI 2023 (ITA), IGT, Trentino – Alto Adige, vinařství Wilhelm Walch"], subtitle: ["Osvěžující víno má světlou, slámově žlutou barvu. Vůně je svěží a elegantní s tóny ovoce a květin. Aroma zralé bílé broskve a citrusů doplněné o náznaky bílých květů. ", "This refreshing wine has a light, straw-yellow color. The aroma is fresh and elegant, with notes of fruit and flowers. It features scents of ripe white peach and citrus, complemented by hints of white flowers."], price: ["440"] },
                    { name: ["“CAMPOGRANDE“ ORVIETO CLASSICO (ITA), DOC, Umbria, Antinori, vinařství Santa Cristina", "“CAMPOGRANDE“ ORVIETO CLASSICO (ITA), DOC, Umbria, Antinori, Santa Cristina Winery"], subtitle: ["Campogrande je směsí odrůd Grechetto a Procanico. Má slámovou barvu, intenzivní vůni s ovocným aroma broskví a meruněk, ale také pomerančových květů.", "Campogrande is a blend of Grechetto and Procanico grape varieties. It has a straw-yellow color, an intense aroma with fruity notes of peaches and apricots, as well as orange blossoms. "], price: ["390"] },
                    { name: ["GRÜNER VELTLINER KLASSIK 2021 (AUT), Weingut Josef Fritz", "GRÜNER VELTLINER KLASSIK 2021(AUT), Weingut Josef Fritz"], subtitle: ["Klasický, čistý a velmi dobře pitelný Veltlín. Pepřová, čerstvá a pikantní vůně s kořenitým aroma. Chuť navazuje na vůni a má výrazný závěr. Ideální především k našim těstovinám. ", "This is a classic, clean, and highly drinkable Veltliner. It has a peppery, fresh, and spicy aroma. The taste follows the aroma and has a pronounced finish. It is ideal especially for our pasta dishes."], price: ["390"] },
                    { name: ["RHEIN RIESLING 2020 (GER), Rhein, J. Baümer", "RHEIN RIESLING 2020 (GER), Rhein, J. Baümer"], subtitle: ["Jde o výrazné víno s citrónově žlutou barvou. Vůně je ovocná s aroma mučenky a liči. Chuť navazuje na vůni, převládají zde tóny peckového ovoce, jablek a citrónů.", "This is a distinctive wine with a lemon-yellow color. The aroma is fruity with hints of passionfruit and lychee. The taste follows the aroma, dominated by notes of stone fruit, apples, lemons."], price: ["390"] },
                ]
            },
            {
                name: ["Růžová vína", "Rose Wines"],
                level: 2,
                items: [
                    { name: ["GIARDINO ROSÉ 2021 (ITA), Toscana IGT, Antinori, vinařství Santa Cristina", "GIARDINO ROSÉ 2021 (ITA), Toscana IGT, Antinori, Santa Cristina Winery"], subtitle: ["Uchvacující, příjemná vůně nabízí aroma květin (růže) a ovoce, například růžový grapefruit, divoké jahody nebo zralá jablka. Chuť je jemná, harmonická a svěží, s pikantním závěrem. Ideální k letním salátům nebo rybám.", "The captivating and pleasant aroma offers a combination of floral notes (rose) and fruit, such as pink grapefruit, wild strawberries, or ripe apples. The taste is gentle, harmonious, and fresh, with a spicy finish. Ideal for summer salads or fish."], price: ["450"] },
                ]
            },
            {
                name: ["Červená vína", "Red Wines"],
                level: 2,
                items: [
                    { name: ["BARBERA GIRIBALDI, PIEMONTE (ITA), DOC, Barbera Piemonte “Alma“", "BARBERA GIRIBALDI, PIEMONTE (ITA), DOC, Barbera Piemonte “Alma“"], subtitle: ["Víno má jasnou, rubínově červenou barvu. Vůně je ovocnáa bohatá s aroma zralých jahod a černých třešní. Chuť je jemná s tóny čerstvého červeného ovoce s lehkou sladkostív závěru.", "The wine has a bright, ruby red color. The aroma is fruity and rich with hints of ripe strawberries and black cherries. The taste is delicate with tones of fresh red fruit and a light sweetness in the finish."], price: ["490"] },
                    { name: ["ACHELO TOSCANA (ITA), DOC, Toscana, Crotona, vinařství La Braccesca", "ACHELO TOSCANA (ITA), DOC, Toscana, Crotona, La Braccesca Winery"], subtitle: ["Samotné víno má purpurovou barvu. Vůně nabízí aroma červeného a černého bobulového ovoce, koření, jako je vanilka a černý pepř. Chuť je plná a bohatá s jemnou ovocnou sladkostí a dlouhým závěrem, ve kterém se objevuje aroma tmavého ovoce, černého rybízu a ostružinového džemu.", "The wine itself has a purple color. The aroma offers notes of red and black berry fruit, spices such as vanilla and black pepper. The taste is full and rich with a gentle fruity sweetness and a long finish."], price: ["790"] },
                    { name: ["PRIMITIVO DI MANDURIA 2022 (ITA), DOC, Puglia, vinařství Masseria Settearchi", "PRIMITIVO DI MANDURIA 2022 (ITA), DOC, Puglia, Masseria Settearchi winery"], subtitle: ["Jedná se o víno vyrobené ze 100 % z odrůdy Primitivo. Víno má temnou, neprůhlednou barvu. Vůně je plná intenzivní, s aroma švestek a džemu z červeného ovoce.", "This is a wine made from 100% Primitivo grapes. The wine has a dark, opaque color. It is very intense, with aromas of wild berries and a hint of red fruit."], price: ["590"] },
                ]
            },
            {
                name: ["Sekty, perlivá vína, Champagne", "Sparkling Wines & Champagne"],
                level: 1,
                items: [
                    { name: ["MONTECAMPO, FAMIGLIA ZONIN, VENETO (ITA), Prosecco Spumante Brut DOC"], subtitle: ["Prosecco Spumante Montecampo má jasnou, světle slámovou barvu. Vůně je intenzivní a krásně ovocná s aroma květů vistárie a čerstvých červených jablek. Chuť je podmaniváa velmi harmonická, s jemnými a delikátními tóny mandlí.", "Prosecco Spumante Montecampo has a clear, pale straw color. The aroma is intense and beautifully fruity with notes of wisteria flowers and fresh red apples. The taste is captivating and very harmonious, with delicate notes of almonds."], price: ["390"] },
                    { name: ["“BAROCCO” Prosecco Spumante, VENETO (ITA), Prosecco Spumante, Extra Dry DOC"], subtitle: ["Víno má světle žlutou barvu s typickým jemným perlením. Komplexní a ovocná vůně s aroma broskví, zelených jablek a dotekem akácie a šeříku. Chuť je lehká a svěží,s harmonickou kyselinou a pevným tělem v déle trvajícím závěru. ", "The wine has a light yellow color with a typical delicate effervescence. The aroma is complex and fruity with notes of peaches, green apples, and a touch of acacia and lilac. The taste is light and fresh, with a harmonious acidity and a firm body in a long-lasting finish. "], price: ["490"] },
                    { name: ["DI STEFANI, VENETO (ITA), Prosecco “0.15” Millesimato Extra Dry DOC"], subtitle: ["Toto Prosecco má světlou, slámově žlutou barvu s jemným a vytrvalým perlením. Vůně je ovocná s intenzivním aroma jablek, banánů a květin. Chuť je hladká se středně plným tělem a osobitým charakterem.", "This Prosecco has a light, straw-yellow color with a fine and persistent effervescence. The aroma is fruity with an intense scent of apples, bananas, and flowers. The taste is smooth with a medium body and a distinctive character."], price: ["590"] },
                    { name: ["VAL D‘OCA, VENETO (ITA), Prosecco Valdobbiadene Superiore Extra Dry Millesimato, DOCG"], subtitle: ["Má slámově žlutou barvu. Vůně je bohatá a ovocná, s tóny žlutého jablka a hrušky, které končí příjemnými citrusovými nuancemi. V ústech je měkký a vyvážený. ", "It has a straw-yellow color. The aroma is rich and fruity, with notes of yellow apple and pear, ending with pleasant citrus nuances. On the palate, it is soft and balanced"], price: ["590"] },
                    { name: ["ASOLO, MONTELVINI, VENETO (ITA), Prosecco Superiore, Extra Brut DOCG"], subtitle: ["Svěží a harmonická chuť, plné tělo a intenzivní požitek - to je několika slovy Asolo Prosecco Superiore DOCG Extra Brut. Ve sklence se leskne světle slámovou barvou se zelenkavými odstíny a na jazyku potěší plnými ovocnými tóny s vyváženými minerálními detaily.", "The fresh and harmonious taste, full body, and intense pleasure - that is Asolo Prosecco Superiore DOCG Extra Brutin a few words. In the glass, it shines with a light straw color with greenish shades and on the palate, it pleases with full fruity tones with balanced mineral details."], price: ["590"] },
                    { name: ["ROSÉ MONTELVINI, VENETO (ITA), Prosecco, Rosé Brut Millesimato, DOC"], subtitle: ["Toto svěží a harmonické prosecco se doporučuje párovat hlavně s rybami a mořskými plody. Nadchne ale i jako svěží aperitiv, a to hlavně díky jeho plné a intenzivní chuti plné lesních plodů a malin.", "This fresh and harmonious prosecco is mainly recommended for pairing with fish and seafood. However, it also delights as a refreshing aperitif, thanks to its full and intense flavor, rich with notes of wild berries and raspberries."], price: ["590"] },
                    { name: ["VUEVE CLIQUOT PONSARDIN (FRA), Champagne, brut"], subtitle: ["Víno má zlatožlutou barvu s jemným, rafinovaným a lehce smetanový perlením. Vůně je ovocná s minerálním podtónem a pečeným toastem. Ve svěží a čerstvé ovocné chuti nalezneme koření, bílý pepř, mletý zázvor a medově-minerální tóny.", "The wine has a golden-yellow color with a fine, refined, and slightly creamy sparkle. The aroma is fruity with a mineral undertone and toasted bread. In the fresh and fruity taste, we can find spices, white pepper, ground ginger, and honey-mineral tones."], price: ["2390"] },
                ]
            }
        ]
    },
    {
        // category: ["Cocktail menu", "Cocktail Menu"],
        // groups: [
        //     {
        //         name: [""],
        //         level: 1,
        //         items: [
        //             { name: ["ROMA"], subtitle: ["Chivas Regal XII / Frangelico / Bitters", "Chivas Regal XII / Frangelico / Bitters"], price: ["129"] },
        //             // { name: ["MODENA"], subtitle: ["Prosecco / Lillet / Galliano / ostružina / citron", "Prosecco / Lillet / Galliano / Blackberry / Lemon"], price: ["129"] },
        //             { name: ["MILANO"], subtitle: ["Campari / Martini Rosso / Beefeater Gin / pomeranč", "Campari / Martini Rosso / Beefeater Gin / Orange"], price: ["129"] },
        //             { name: ["TORINO"], subtitle: ["Martini Rosato / Malfy Gin Rosa / Campari / soda", "Martini Rosato / Malfy Gin Rosa / Campari / Soda"], price: ["129"] },
        //             { name: ["VENEZIA"], subtitle: ["Aperol / Hendrick’s Gin / broskev / prosecco", "Aperol / Hendrick's Gin / Peach / Prosecco"], price: ["129"] },
        //             // { name: ["VICENZA"], subtitle: ["Havana Club Añejo 3 Años / jahoda / citron", "Havana Club Añejo 3 Años / Strawberry / Lemon"], price: ["129"] },
        //             { name: ["LIVIGNO"], subtitle: ["Jägermeister Manifest / Wild Turkey / Martini Bianco", "Jägermeister Manifest / Wild Turkey / Martini Bianc"], price: ["129"] },
        //             { name: ["NAPOLI"], subtitle: ["Havana Club Añejo 3 Años / mango / marakuja / citrus / soda", "Havana Club Añejo 3 Años / Mango / Passion Fruit / Citrus / Soda"], price: ["129"] },
        //             { name: ["PALERMO"], subtitle: ["Averna Amaro / Havana Club Añejo 3 Años / ananas / citrus", "Averna Amaro / Havana Club Añejo 3 Años / Pineapple / Citrus"], price: ["129"] },
        //             { name: ["VERONA"], subtitle: ["Skyy Vodka / Luxardo Maraschino / aronie / citrus / soda", "Skyy Vodka / Luxardo Maraschino / Aronia / Citrus / Soda"], price: ["129"] },
        //             { name: ["FIRENZE"], subtitle: ["Galliano / Skyy Vodka / espresso", "Galliano / Skyy Vodka / Espresso"], price: ["129"] },
        //             { name: ["BARI"], subtitle: ["Amaretto Disaronno / Olmeca tequila Blanco / citrus", "Amaretto Disaronno / Olmeca tequila Blanco / citrus"], price: ["129"] },
        //             // { name: ["CATANIA"], subtitle: ["Beefeater Gin / Triple sec / grapefruit / citron", "Beefeater Gin / Triple sec / Grapefruit / Lemon"], price: ["129"] },


        //         ]
        //     },
        //     {
        //         name: ["Letní speciály", "Summer Specials"],
        //         level: 1,
        //         items: [           
        //             // { name: ["Mango-maracuja limonáda", "Mango & Passion Fruit Lemonade"], price: ["69"] },      
        //             // { name: ["Borůvková limonáda", "Blueberry Lemonade"], price: ["69"] },
        //             { name: ["Šípková limonáda", "Blueberry Lemonade"], price: ["69"] },
        //             { name: ["Hrušková limonáda", "Blueberry Lemonade"], price: ["69"] },
        //             { name: ["Čajová infuze", "Blueberry Lemonade"], subtitle: "Bylinný čaj infuzovaný rakytníkem nebo šípkem", price: ["69"] },
        //             { name: ["Cafe New One punč", "Blueberry Lemonade"], price: ["79"] },
        //             { name: ["Horká čokoláda s marshmallows", "Blueberry Lemonade"], price: ["79"] },
        //             { name: ["Dýňově/Perníkové/Skořicové", "Blueberry Lemonade"], price: ["89"] },
        //             { name: ["Bailey's cofee", "Blueberry Lemonade"], price: ["99"] },
        //             // { name: ["Grepová limonáda", "Grapefruit Lemonade"], price: ["69"] },
        //             // { name: ["Angreštová limonáda", "Gooseberry Lemonade"], price: ["69"] },
        //             // { name: ["Levandulová limonáda", "Lavender Lemonade"], price: ["69"] },
        //             // { name: ["Ledový čaj (broskev, citron)", "Iced Tea (Peach, Lemon)"], price: ["69"] },
        //         ]
        //     },
        //     {
        //         name: ["Kávové letní speciály", "Coffee Summer Specials"],
        //         level: 2,
        //         items: [
        //             { name: ["Matcha crème Frappuccino (vanilka, kokosové mléko)", "Matcha crème Frappuccino (Vanilla, Coconut Milk)"], price: ["99"] },
        //             { name: ["Pistáciové Iced latte", "Pistachio Iced Latte"], price: ["89"] },
        //             { name: ["Karamelové Iced cappuccino", "Caramel Iced Cappuccino"], price: ["79"] },
        //         ]
        //     },
        //     {
        //         name: ["Signature", "Signature"],
        //         level: 2,
        //         items: [
        //             // { name: ["TOUCH THE WORLD"], subtitle: ["Jägermeister Manifest / Metaxa 12* / espresso / limeta", "Jägermeister Manifest / Metaxa 12* / Espresso / Lime"], price: ["149"] }
        //             { name: ["ROSE DREAM"], subtitle: ["Metaxa 12* / Rose lemonade / cascara / limeta / orange bitters", "Metaxa 12* / Rose lemonade / cascara / limeta / orange bitters"], price: ["149"] },
        //             { name: ["CHRISTMAS FIZZ"], subtitle: ["Gin / brusinka / prosecco", "MGin / brusinka / prosecco"], price: ["129"] },
        //         ]
        //     },
        //     {
        //         name: ["NON-ALCO"],
        //         level: 2,
        //         items: [
        //             { name: ["RIMINI"], subtitle: ["Crodino / pomeranč / soda", "Crodino / Orange / Soda"], price: ["89"] },
        //             { name: ["BERGAMO"], subtitle: ["Ananas / bezinka / citrus / soda", "Pineapple / Elderberry / Citrus / Soda"], price: ["89"] },
        //         ]
        //     },
        // ]
        category: ["Cocktail menu", "Cocktail Menu"],
        groups: [
            {
                name: [""],
                level: 1,
                items: [
                    // { name: ["ROMA"], subtitle: ["Chivas Regal XII / Frangelico / Bitters", "Chivas Regal XII / Frangelico / Bitters"], price: ["129"] },
                    { name: ["MODENA"], subtitle: ["Prosecco / Lillet / Galliano / ostružina / citron", "Prosecco / Lillet / Galliano / Blackberry / Lemon"], price: ["129"] },
                    { name: ["NAPOLI"], subtitle: ["Havana Club Añejo 3 Años / mango / marakuja / citrus / soda", "Havana Club Añejo 3 Años / Mango / Passion Fruit / Citrus / Soda"], price: ["129"] },
                    { name: ["MILANO"], subtitle: ["Campari / Martini Rosso / Beefeater Gin / pomeranč", "Campari / Martini Rosso / Beefeater Gin / Orange"], price: ["129"] },
                    { name: ["PALERMO"], subtitle: ["Averna Amaro / Havana Club Añejo 3 Años / ananas / citrus", "Averna Amaro / Havana Club Añejo 3 Años / Pineapple / Citrus"], price: ["129"] },
                    { name: ["TORINO"], subtitle: ["Martini Rosato / Malfy Gin Rosa / Campari / soda", "Martini Rosato / Malfy Gin Rosa / Campari / Soda"], price: ["129"] },
                    { name: ["VERONA"], subtitle: ["Skyy Vodka / Luxardo Maraschino / aronie / citrus / soda", "Skyy Vodka / Luxardo Maraschino / Aronia / Citrus / Soda"], price: ["129"] },
                    { name: ["VENEZIA"], subtitle: ["Aperol / Hendrick’s Gin / broskev / prosecco", "Aperol / Hendrick's Gin / Peach / Prosecco"], price: ["129"] },
                    // { name: ["VICENZA"], subtitle: ["Havana Club Añejo 3 Años / jahoda / citron", "Havana Club Añejo 3 Años / Strawberry / Lemon"], price: ["129"] },
                    { name: ["FIRENZE"], subtitle: ["Galliano / Skyy Vodka / espresso", "Galliano / Skyy Vodka / Espresso"], price: ["129"] },
                    { name: ["VICENZA"], subtitle: ["Havana Club Añejo 3 Años / jahoda / citron", "Havana Club Añejo 3 Years / Strawberry / Lemon"], price: ["129"] },
                    { name: ["CATANIA"], subtitle: ["Beefeater Gin / Triple sec / grapefruit / citron", "Beefeater Gin / Triple sec / Grapefruit / Lemon"], price: ["129"] },
                    // { name: ["LIVIGNO"], subtitle: ["Jägermeister Manifest / Wild Turkey / Martini Bianco", "Jägermeister Manifest / Wild Turkey / Martini Bianc"], price: ["129"] },
                    // { name: ["BARI"], subtitle: ["Amaretto Disaronno / Olmeca tequila Blanco / citrus", "Amaretto Disaronno / Olmeca tequila Blanco / citrus"], price: ["129"] },
                    // { name: ["CATANIA"], subtitle: ["Beefeater Gin / Triple sec / grapefruit / citron", "Beefeater Gin / Triple sec / Grapefruit / Lemon"], price: ["129"] },
                ]
            },
            {
                name: ["NON-ALCO"],
                level: 2,
                items: [
                    { name: ["RIMINI"], subtitle: ["Crodino / pomeranč / soda", "Crodino / Orange / Soda"], price: ["99"] },
                    { name: ["BERGAMO"], subtitle: ["Ananas / bezinka / citrus / soda", "Pineapple / Elderberry / Citrus / Soda"], price: ["99"] },
                ]
            },
            {
                name: ["Specials", "Specials"],
                level: 1,
                items: [
                    // { name: ["Mango-maracuja limonáda", "Mango & Passion Fruit Lemonade"], price: ["69"] },      
                    // { name: ["Borůvková limonáda", "Blueberry Lemonade"], price: ["69"] },
                    { name: ["Limonáda z lesních jahod", "Wild strawberries lemonade"], price: ["79"] },
                    { name: ["Limonáda Mango & Maracuja", "Mango & Maracuya lemonade"], price: ["79"] },
                    { name: ["Borůvková limonáda", "Blueberry lemonade"], price: ["79"] },
                    { name: ["Ice Tea Aloe Vera / Citron / Broskev / Passion fruit a máta", "Ice Tea Aloe Vera / Lemon / Peach / Passion fruit and mint"], price: ["79"] },
                    { name: ["Caffé Affogato", "Caffé Affogato"], price: ["79"] },
                    { name: ["Iced caramel cappucino", "Iced caramel cappucino"], price: ["89"] },
                    { name: ["Čokoládové iced latté se šlehačkou", "Chocolate iced latte with whipped cream"], price: ["99"] },
                    // { name: ["Grepová limonáda", "Grapefruit Lemonade"], price: ["69"] },
                    // { name: ["Angreštová limonáda", "Gooseberry Lemonade"], price: ["69"] },
                    // { name: ["Levandulová limonáda", "Lavender Lemonade"], price: ["69"] },
                    // { name: ["Ledový čaj (broskev, citron)", "Iced Tea (Peach, Lemon)"], price: ["69"] },
                ]
            },
            {
                name:["Letní cocktaily", "Summer cocktails"],
                level:2,
                items: [
                    {name: ['Hugo spritz', 'Hugo spritz'], price:["119"]},
                    {name: ['Campari spritz', 'Campari spritz'], price:["119"]},
                    {name: ['Limoncello spritz', 'Limoncello spritz'], price:["119"]},
                    {name: ['Orange spritz', 'Orange spritz'], price:["119"]},
                ]
            },
            // {
            //     name: ["Kávové letní speciály", "Coffee Summer Specials"],
            //     level: 2,
            //     items: [
            //         { name: ["Matcha crème Frappuccino (vanilka, kokosové mléko)", "Matcha crème Frappuccino (Vanilla, Coconut Milk)"], price: ["99"] },
            //         { name: ["Pistáciové Iced latte", "Pistachio Iced Latte"], price: ["89"] },
            //         { name: ["Karamelové Iced cappuccino", "Caramel Iced Cappuccino"], price: ["79"] },
            //     ]
            // },
            {
                name: ["Signature cocktails", "Signature cocktails"],
                level: 2,
                items: [
                    // { name: ["TOUCH THE WORLD"], subtitle: ["Jägermeister Manifest / Metaxa 12* / espresso / limeta", "Jägermeister Manifest / Metaxa 12* / Espresso / Lime"], price: ["149"] }
                    // { name: ["ROSE DREAM"], subtitle: ["Metaxa 12* / Rose lemonade / cascara / limeta / orange bitters", "Metaxa 12* / Rose lemonade / Cascara / Lime / Orange Bitters"], price: ["149"] },
                    { name: ["Sunshine"], subtitle: ["Gin / Mango / Ananas / Pomeranč / Citron", "Gin / Mango / Pineapple / Orange / Lemon"], price: ["149"] },
                ]
            },
        ]
    }




] as Array<menuType>